<template>
  <div v-if="sponsored" class="featured-icon">
    {{ sponsoredText }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    sponsored: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      sponsoredText: (state) => state?.sponsored_text ?? 'FEATURED',
    }),
  },
};
</script>
